<template>
  <div class="cpld">
    <div class="webCont">
      <div class="indexTitle">
        <p>产品亮点</p>
        <span></span>
      </div>
      <div class="cont">
        <div class="leftMenu" id="leftMenu">
          <div class="menuItem on">
            <div class="img img1"></div>校本职称
            <span></span>
          </div>
          <div class="menuItem">
            <div class="img img2"></div>能力发展模型
            <span></span>
          </div>
          <div class="menuItem">
            <div class="img img3"></div>智慧研修
            <span></span>
          </div>
          <div class="menuItem">
            <div class="img img4"></div>职称诊断
            <span></span>
          </div>
          <div class="menuItem">
            <div class="img img5"></div>评教评学
            <span></span>
          </div>
          <div class="menuItem">
            <div class="img img6"></div>发展报告
            <span></span>
          </div>
        </div>
        <div class="rightCount">
          <div class="item" id="xbzc">
            <div class="tit">校本职称</div>
            <div class="des">
              立足学校发展，围绕课程设计、课程实施、课程评估3个教学环节15个能力观测点建立以培养目标为中心的校本职称体系，通过互惠尽责，形成教师合力，推动学校发展。
            </div>
            <img src="static/lvya/schoolFzpt/teacherDevelopment/cpld-img01.png" alt="">
          </div>
          <div class="item">
            <div class="tit">能力发展模型</div>
            <div class="des">
              以学生培养目标为核心，建立科学的教师能力发展标准，以发展标准贯穿教师工作场景、汇聚场景数据，通过过程性评价+表现性评价，全面科学剖析教师能力薄弱点，促进教师个人能力提升，推动教师队伍高质量发展。
            </div>
            <img src="static/lvya/schoolFzpt/teacherDevelopment/cpld-img02.png" alt="">
          </div>
          <div class="item">
            <div class="tit">智慧研修</div>
            <div class="des">
              技术赋能教师工作全场景，助力教师实现校本资源建设、常态化听评课、精准教研、教师培训等工作的增质提效。
            </div>
            <img src="static/lvya/schoolFzpt/teacherDevelopment/cpld-img03.png" alt="">
          </div>
          <div class="item">
            <div class="tit">职称诊断</div>
            <div class="des">
              根据学校职称、等级晋升方案，灵活配置教师晋升之路。自定义配置各种学历条件下，教师晋级到下一等级需要完成的条件，为学校教师职称诊断提供了便利。
            </div>
            <img src="static/lvya/schoolFzpt/teacherDevelopment/cpld-img04.png" alt="">
          </div>
          <div class="item">
            <div class="tit">评教评学</div>
            <div class="des">
              构建多元化、多维度综合评价体系，实现对教师系统、科学的评价，以评促教，引领教师专业发展。
            </div>
            <img src="static/lvya/schoolFzpt/teacherDevelopment/cpld-img05.png" alt="">
          </div>
          <div class="item">
            <div class="tit">发展报告</div>
            <div class="des">
              灵活的发展报告生成系统，兼顾各学科、部门教师工作特性，生成具有学校特色、岗位特征的教师全面发展报告。
            </div>
            <img src="static/lvya/schoolFzpt/teacherDevelopment/cpld-img06.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    let isClick = false
    $('.cpld .cont .leftMenu .menuItem').click(function() {
      isClick = true
      let index = $('.cpld .cont .leftMenu .menuItem').index(this)
      $('.cpld .cont .leftMenu .menuItem').removeClass('on').eq(index).addClass('on')
      let top = $('.cpld .cont .rightCount .tit').eq(index).offset().top - 70
      $('body,html').animate({ scrollTop: top }, 500)
      setTimeout(function() {
        isClick = false
      }, 600)
    })
    $(window).scroll(function() {
      let windowScrollTop = $(window).scrollTop() + 70
      // 菜单悬浮
      let flagTop = $('.cpld .cont .rightCount').offset().top
      let flagLeft = $('.cpld .cont').offset().left
      // footer距离顶部的高度 - 左侧菜单高度 - 导航高度
      let bottomScrollTop = $('.footer').offset().top - 564 - 70
      if (windowScrollTop > flagTop) {
        // 菜单停止悬浮
        if (windowScrollTop > bottomScrollTop) {
          $('.cpld .cont .leftMenu').removeClass('fixed').css({ 'left': 0, 'top': 'auto', 'bottom': 0 })
        } else {
          $('.cpld .cont .leftMenu').addClass('fixed').css('left', flagLeft)
        }
      } else {
        $('.cpld .cont .leftMenu').removeClass('fixed').css({ 'left': 0, 'top': 0, 'bottom': 'auto' })
      }
      if (isClick) return
      let arr = []
      for (let i = 0; i < 6; i++) {
        let top = $('.cpld .cont .rightCount .tit').eq(i).offset().top - 70 - ($(window).height() / 2)
        if (windowScrollTop > top) {
          arr.push(i)
        }
      }
      if (arr.length > 0) {
        $('.cpld .cont .leftMenu .menuItem').removeClass('on').eq(arr[arr.length - 1]).addClass('on')
      } else {
        $('.cpld .cont .leftMenu .menuItem').removeClass('on').eq(0).addClass('on')
      }
    })
  },
}
</script>
<style lang="less">
  .cpld{
    .cont{
      position: relative;
      padding-left: 250px;
      .leftMenu{
        position: absolute;
        left: 0px;
        top: 0;
        &.fixed{
          position: fixed;
          top: 70px!important;
        }
        .menuItem{
          width: 200px;
          height: 74px;
          background: #F4F6F9;
          border-radius: 8px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          position: relative;
          cursor: pointer;
          .img{
            width: 45px;
            height: 45px;
            border-radius: 50%;
            margin: 0 15px 0 20px;
            &.img1{
              background: url(../../../../../public/static/lvya/schoolFzpt/teacherDevelopment/cpld-icon1.png) no-repeat center;
              background-size: 40%;
            }
            &.img2{
              background: url(../../../../../public/static/lvya/schoolFzpt/teacherDevelopment/cpld-icon2.png) no-repeat center;
              background-size: 50%;
            }
            &.img3{
              background: url(../../../../../public/static/lvya/schoolFzpt/teacherDevelopment/cpld-icon3.png) no-repeat center;
              background-size: 50%;
            }
            &.img4{
              background: url(../../../../../public/static/lvya/schoolFzpt/teacherDevelopment/cpld-icon4.png) no-repeat center;
              background-size: 40%;
            }
            &.img5{
              background: url(../../../../../public/static/lvya/schoolFzpt/teacherDevelopment/cpld-icon5.png) no-repeat center;
              background-size: 40%;
            }
            &.img6{
              background: url(../../../../../public/static/lvya/schoolFzpt/teacherDevelopment/cpld-icon6.png?v=1.0) no-repeat center;
              background-size: 40%;
            }
          }
          span{
            display: block;
            opacity: 0;
            width: 0;
            height: 0;
            border-top: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 12px solid #19CA81;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -12px;
          }
          &.on,&:hover{
            background: #19CA81;
            color: #fff;
            transition: all 0.5s;
            .img{
              background-color: #fff;
            }
          }
          &.on{
            span{
              opacity: 1;
              right: -35px;
              transition: all 0.5s;
            }
          }
        }
      }
      .rightCount{
        // width: 900px;
        margin: 0 auto;
        .item{
          .tit{
            font-size: 24px;
            font-weight: 500;
            color: #222222;
            margin-bottom: 15px;
            padding-left: 30px;
            background: url(../../../../../public/static/lvya/schoolFzpt/index/sh-circle.png) no-repeat left center;
          }
          .des{
            font-size: 18px;
            font-weight: 300;
            color: #696969;
            line-height: 35px;
          }
          img{
            margin-left: -90px;
          }
        }
      }
    }
  }
</style>
