<template>
  <div class="teacherDevelopment">
    <div class="banner">
      <img src="static/lvya/banner/02.png" alt="">
    </div>
    <TeaCont1></TeaCont1>
    <TeaCont2></TeaCont2>
  </div>
</template>
<script>
import TeaCont1 from './coms/TeaCont1.vue'
import TeaCont2 from './coms/TeaCont2.vue'
export default {
  components: { TeaCont1, TeaCont2 }
}
</script>
<style lang="less">
  .teacherDevelopment{}
  .banner{
    max-width: 1920px;
    margin: 0 auto;
    img{
      width: 100%;
    }
  }
</style>
